import { graphql } from 'gatsby';
import React from 'react';
import SingleTemplate from './single';
import { getIndexPageMeta } from '../utils/helpers';
import { PAGE_TYPE, PAGE_PREFIX } from '../utils/constants';
import AcademyProxy from '../components/proxy/academy-blocks';

const AcademyIndexTemplate = props => {
  const { site, markdownRemark } = props.data;

  const seo = getIndexPageMeta({
    pathname: props.location.pathname,
    siteMetadata: site.siteMetadata,
    frontmatter: markdownRemark.frontmatter,
  });

  return (
    <SingleTemplate
      seo={seo}
      pageType={PAGE_TYPE.Academy}
      pagePrefix={PAGE_PREFIX.Academy}
      proxy={AcademyProxy}
      {...props}
    />
  );
};

export default AcademyIndexTemplate;

export const academyIndexPageQuery = graphql`
  query AcademyIndexPageQuery {
    featuredCategories: allAcademyFeatured {
      edges {
        node {
          Id
          Title
          ArticlesCount
          Shortcode
          Name
          Articles {
            TopicName
            UrlPath
            ListTitle
          }
        }
      }
    }
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: "/academy-index/" } }) {
      ...PageMarkdownFragment
    }
    ...ConfigFragment
  }
`;
